import _index6 from "./_lib/formatDistance/index.js";
import _index7 from "./_lib/formatLong/index.js";
import _index8 from "./_lib/formatRelative/index.js";
import _index9 from "./_lib/localize/index.js";
import _index10 from "./_lib/match/index.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(_index6);

var _index2 = _interopRequireDefault(_index7);

var _index3 = _interopRequireDefault(_index8);

var _index4 = _interopRequireDefault(_index9);

var _index5 = _interopRequireDefault(_index10);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * @type {Locale}
 * @category Locales
 * @summary Spanish locale.
 * @language Spanish
 * @iso-639-2 spa
 * @author Juan Angosto [@juanangosto]{@link https://github.com/juanangosto}
 * @author Guillermo Grau [@guigrpa]{@link https://github.com/guigrpa}
 * @author Fernando Agüero [@fjaguero]{@link https://github.com/fjaguero}
 * @author Gastón Haro [@harogaston]{@link https://github.com/harogaston}
 * @author Yago Carballo [@YagoCarballo]{@link https://github.com/YagoCarballo}
 */


var locale = {
  code: "es",
  formatDistance: _index.default,
  formatLong: _index2.default,
  formatRelative: _index3.default,
  localize: _index4.default,
  match: _index5.default,
  options: {
    weekStartsOn: 1
    /* Monday */
    ,
    firstWeekContainsDate: 1
  }
};
var _default = locale;
exports.default = _default;
exports = exports.default;
export default exports;
export const __esModule = exports.__esModule;
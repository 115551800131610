import _index2 from "../../../_lib/buildFormatLongFn/index.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(_index2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var dateFormats = {
  full: "EEEE, d 'de' MMMM 'de' y",
  long: "d 'de' MMMM 'de' y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats = {
  full: "{{date}} 'a las' {{time}}",
  long: "{{date}} 'a las' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
var formatLong = {
  date: (0, _index.default)({
    formats: dateFormats,
    defaultWidth: "full"
  }),
  time: (0, _index.default)({
    formats: timeFormats,
    defaultWidth: "full"
  }),
  dateTime: (0, _index.default)({
    formats: dateTimeFormats,
    defaultWidth: "full"
  })
};
var _default = formatLong;
exports.default = _default;
exports = exports.default;
export default exports;